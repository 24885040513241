import { computed, inject } from '@angular/core';
import { SESSION_STORAGE } from '@ng-web-apis/common';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { withDevtools, withStorageSync } from '@angular-architects/ngrx-toolkit'; // docs: https://github.com/angular-architects/ngrx-toolkit
import { userInitialState } from './user-initial-state';
import { EntryType, UserState, UserType } from './user.model';
import { StoreStatus } from '../store-status.model';

/**
 * User Store
 * - this is provided in the orchestration component
 * Great Intro Post: https://offering.solutions/blog/articles/2023/12/03/ngrx-signal-store-getting-started/
 */
export const UserStore = signalStore(
  { providedIn: 'root' },
  withState(userInitialState),
  // Selectors
  withComputed((store) => ({
    isLoading: computed(() => store.storeStatus() === 'loading'),
    isError: computed(() => typeof store.storeStatus() === 'object'),
  })),
  withMethods((store) => ({
    reset(): void {
      patchState(store, () => userInitialState);
    },
    patchStore: (newState: Partial<UserState>) => {
      patchState(store, () => newState);
    },
    setEntryType: (entryType: EntryType) => {
      patchState(store, () => ({ entryType }));
    },
    setUserType: (userType: UserType) => {
      patchState(store, () => ({ userType }));
    },
    updateStatus: (status: StoreStatus) => {
      patchState(store, () => ({ storeStatus: status }));
    },
  })),
  withStorageSync({
    key: 'user',
    autoSync: true,
    storage: () => inject(SESSION_STORAGE),
  }),
  withDevtools('userInitialState'),
);
