/**
 * Resume Mapping
 *
 * This file is EXTREMELY important.  We interpret what the API is giving to us and translating it into the frontend.
 * Risk: mapping a value wrong could result in overwriting data that we don't want to change.
 *
 * This file should always have 100% test coverage and any changes be well tested
 *
 * Related to `map-legacy-users-to-quote-draft-store.ts`
 * Used by the `sync.service.ts`
 *
 * @todo QT-268 will move the following out of the Quote Draft Store
 * - selectedPackageInfo
 * - policyInfo
 */
import { LegacyApiPagesCir } from '../quote-pages';
import { QuoteDraftApplicant, QuoteDraftCoApplicant, QuoteDraftState } from '../../../../global-store/quote-draft';
import { InsuranceStatusValue } from '../../../../create-quote/forms/insurance-status/insurance-status.types';
import { RentalNumberValue } from '../../../../create-quote/forms/rental-number/rental-number.types';
import { translateApiNullToUndefined, translateLegacyNumber, translateLegacyPhone, translateLegacyStringBoolean } from './utilities';
import { isIsoDate } from '../../../utils/iso-date-type/to-iso-date';

export type QuoteDraftStateLegacyAddressApiMapping = Omit<QuoteDraftState, 'storeStatus' | 'draftToken' | 'cirId' | 'address'>;

/**
 * This will effectively rest the store
 * @param payload
 * @returns
 */
export function mapLegacyAnswersToQuoteDraftStore(payload: Partial<LegacyApiPagesCir>): Omit<QuoteDraftState, 'storeStatus' | 'draftToken' | 'cirId' | 'address'> | undefined {
  if (payload && Object.keys(payload).length > 0) {
    return {
      applicant: processApplicant(payload),
      hasOptedIntoSms: translateLegacyStringBoolean(payload.opt_in_text) ?? false,
      policyType: payload.policy_type ?? undefined,
      hasUnitNumber: processUnitNumber(payload),
      unitNumber: payload.unit_number ?? undefined,
      insuranceStatus: processInsuranceStatus(payload),
      isInsuranceExpiredMoreThan30Days: processInsuranceExpiration(payload),
      rentalStatus: processRentalStatus(payload),
      rentalNumber: processRentalNumber(payload),
      residency: translateLegacyNumber(payload.months_occupied),
      policyStartDate: isIsoDate(payload.effective_date) ? payload.effective_date : undefined,
      hasCoApplicant: translateLegacyStringBoolean(payload.include_coapplicant),
      coApplicant: processCoApplicant(payload),
      propertyType: payload.property_type ?? undefined,
      roofShape: payload.roof_shape ?? undefined,
      yearBuilt: translateLegacyNumber(payload.year_built),
      sqFt: translateLegacyNumber(payload.sq_ft),
      stories: translateLegacyNumber(payload.stories),
      pool: translateLegacyStringBoolean(payload.pool), // @todo - update to hasPool
      constructionType: payload.construction_type ?? undefined,
      roofYear: translateLegacyNumber(payload.roof_construction_year),
      exteriorWall: payload.exterior_wall_finish ?? undefined,
      withinPark: translateLegacyStringBoolean(payload.within_park),
      bathrooms: translateLegacyNumber(payload.bathrooms),
      roofHasSolarPanels: translateLegacyStringBoolean(payload.roof_solar_panels),
      acreage: translateLegacyNumber(payload.acreage),
      purchaseDate: isIsoDate(payload.purchase_date) ? payload.purchase_date : undefined,
      roofType: payload.roof_type ?? undefined,
      foundationType: payload.foundation ?? undefined,
      policyGoal: undefined,
      selectedPackageInfo: undefined,
    };
  }
  return undefined;
}

function processRentalNumber(payload: Partial<LegacyApiPagesCir>): RentalNumberValue | undefined {
  const timesRented = payload.times_rented;
  if (timesRented === undefined || timesRented === null || timesRented === '') {
    return undefined;
  }

  const rentalCount = translateLegacyNumber(timesRented);
  switch (true) {
    case rentalCount === undefined:
      return undefined;
    case rentalCount === 0:
      return 0;
    case rentalCount! > 9:
      return 10;
    case rentalCount! > 5:
      return 9;
    case rentalCount! <= 5:
      return 5;
    default:
      return undefined;
  }
}

function processUnitNumber(payload: Partial<LegacyApiPagesCir>): boolean | undefined {
  return payload.unit_number_check === undefined ? undefined : payload.unit_number_check !== null;
}

function processApplicant(payload: Partial<LegacyApiPagesCir>): QuoteDraftApplicant | undefined {
  if (payload && Object.keys(payload).length > 0) {
    const firstName = translateApiNullToUndefined(payload.first_name);
    const lastName = translateApiNullToUndefined(payload.last_name);
    const phoneNumber = translateLegacyPhone(payload.phone_number);
    const dob = translateApiNullToUndefined(payload.dob);
    const email = translateApiNullToUndefined(payload.email);

    if ([firstName, lastName, phoneNumber, dob, email].every((value) => value === undefined || value === null)) {
      return undefined;
    }

    return {
      firstName: firstName!,
      lastName: lastName!,
      phoneNumber: phoneNumber!,
      dob: dob!,
      email: email!,
    };
  }
  return undefined;
}

/**
 * while in the legacy payload mapping file we are interpreting null as "yes", the API also defaults to null without an answer.
 * this is why we are mapping null to undefined, because we will not know if it is really "yes" or they haven't answered it yet.
 */
function processRentalStatus(payload: Partial<LegacyApiPagesCir>): boolean | undefined {
  const timesRented = translateLegacyNumber(payload.times_rented);
  if (typeof timesRented === 'number') {
    return timesRented > 0;
  }
  return undefined;
}

/**
 * Process the Insurance Status property
 */
function processInsuranceStatus(payload: Partial<LegacyApiPagesCir>): InsuranceStatusValue | undefined {
  switch (payload.current_insurance_status) {
    case 'currently_insured':
      return 'currently_insured';

    case 'new_purchase_new_policy':
      return 'new_purchase_new_policy';

    case 'new_purchase_new_policy_first_time':
      return 'new_purchase_new_policy_first_time';

    case 'no_insurance':
    case 'expired_more_than_30_days':
    case 'expired_less_than_30_days':
      return 'no_insurance';

    default:
      return undefined;
  }
}

function processInsuranceExpiration(payload: Partial<LegacyApiPagesCir>): boolean | undefined {
  switch (payload.current_insurance_status) {
    case 'expired_more_than_30_days':
      return true;
    case 'expired_less_than_30_days':
      return false;
    default:
      return undefined;
  }
}

/**
 * Processes the co-applicant information from the payload.
 *
 * @param {LegacyApiPagesCir} payload - The legacy payload containing co-applicant details.
 * @returns {QuoteDraftCoApplicant | undefined} - The coApplicant object if any details are present, otherwise undefined.
 */
function processCoApplicant(payload: Partial<LegacyApiPagesCir>): QuoteDraftCoApplicant | undefined {
  if (payload && Object.keys(payload).length > 0) {
    const firstName = translateApiNullToUndefined(payload.coapplicant_first_name);
    const lastName = translateApiNullToUndefined(payload.coapplicant_last_name);
    const dob = translateApiNullToUndefined(payload.coapplicant_dob);
    if ([firstName, lastName, dob].every((value) => value === undefined || value === null)) {
      return undefined;
    }

    return {
      firstName: firstName!,
      lastName: lastName!,
      dob: dob!,
    };
  }
  return undefined;
}
