/* eslint-disable prettier/prettier */
/**
 * Quote Draft Sync Service
 * - ability to sync UI state to the API (for debugging)
 * - ability to sync API state with UI (aka resume)
 */
import { inject, Injectable } from '@angular/core';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';
import type { QuoteDraftState } from '../../../global-store/quote-draft';
import type { QuoteDraftEntryRouteTo } from '../entry/quote-draft-entry.service';
import type { LegacyApiPagesResponse } from '../../../common/services/legacy/quote-pages';
import { LegacyAnswersApiService } from '../../../common/services/legacy/answers/answers.service';
import { LegacyUsersApiService } from '../../../common/services/legacy/users/users.service';
import { LegacyPagesApiService } from '../../../common/services/legacy/quote-pages';
import { mapLegacyUsersToQuoteDraftStore } from '../../../common/services/legacy/response-mapping/map-legacy-users-to-quote-draft-store';

import { mapLegacyAnswersToQuoteDraftStore } from '../../../common/services/legacy/response-mapping/map-legacy-answers-to-quote-draft-store';

export type RestoreQuoteDraft = {
  entryRoute: QuoteDraftEntryRouteTo;
  restoredState: Partial<QuoteDraftState>;
  pageResponse?: LegacyApiPagesResponse;
};

@Injectable({
  providedIn: 'root',
})
export class QuoteDraftSyncService {
  private answersService = inject(LegacyAnswersApiService);
  private userService = inject(LegacyUsersApiService);
  private pagesService = inject(LegacyPagesApiService);

  /**
   * A token needs to be set in the store for this to work, if it is not the API will reject the request
   *
   * The user service may error if called prior to user creation (before about you page submission)
   * so we catch errors for that one. This should only happen if a user tries to resume a quote
   * that only has an address entered.
   */
  public restoreStore(): Observable<RestoreQuoteDraft> {
    return forkJoin([this.answersService.getAnswersApi(), this.userService.getUserFromApi().pipe(catchError(() => of(undefined))), this.pagesService.pollForNextPage()]).pipe(
      map(([answersRes, userRes, pgRes]) => {
        let entryRoute!: QuoteDraftEntryRouteTo;
        let restoredState: Partial<QuoteDraftState> = mapLegacyAnswersToQuoteDraftStore(answersRes) || {};
        let pageResponse!: LegacyApiPagesResponse | undefined;
        if (userRes) {
          restoredState = { ...restoredState, ...(mapLegacyUsersToQuoteDraftStore(userRes) || {}) };
        }
        if (pgRes?.page) {
          pageResponse = pgRes;
          entryRoute = { type: 'form', route: pgRes.page.path };
        }
        if (pgRes?.redirect_to) {
          entryRoute = { type: 'redirect', route: pgRes.redirect_to };
        }
        return { entryRoute, restoredState, pageResponse };
      }),
    );
  }

  /**
   * This is a special case for click leads, we only need to retrieve data from the /answers endpoint
   * which provides data from the CustomerInput::Repsonse model (CIR). This is so we can prefill
   * the form fields on the page that the user is redirected to (which at the time of writing this is only the About You Page).
   *
   * There is no User to retrieve data from for Click Leads.
   *
   * There is no need to retrieve the next page from the PagesController since the ClickLeadsController returns the redirect URL.
   */
  public restoreStoreForClickLead(): Observable<Partial<QuoteDraftState>> {
    return this.answersService.getAnswersApi().pipe(
      map((answersRes) => {
        return mapLegacyAnswersToQuoteDraftStore(answersRes) || {};
      }),
    );
  }
}
